import mapObject from '../utils/mapObject';

import Reservation from './Reservation';

export default class Team {
    static pickupPlaceName = {
        'hongdae': '홍대',
        'myungdong': '명동',
        'dongdaemoon': '동대문',
        'busan station': '부산역',
        'seomyun': '서면',
        'haeundae': '해운대',
        'tokyo station': '도쿄역',
        'shinjuku-nishiguchi':'신주쿠니시구치역',
        'tsurutontan soemoncho': '츠루톤탄 소에몬초'
    };

    static pickupPlaceOrder = {
        'hongdae': 0,
        'myungdong': 1,
        'dongdaemoon': 2,
        'busan station': 5,
        'seomyun': 6,
        'haeundae': 7,
        'tokyo station': 8,
        'shinjuku-nishiguchi': 9,
        'tsurutontan soemoncho' : 10
    };

    static arrangePickupPlaces(reservations) {
        const reservationList = Object.values(reservations);
        const pickupPlaceReservationsMap = reservationList.reduce((result, reservation) => {
            if (!result[reservation.pickupPlace]) result[reservation.pickupPlace] = {
                reservations: [],
                shownPeople: 0,
                people: 0,
                adult: 0,
                kid: 0,
                infant: 0,
            };
            result[reservation.pickupPlace].reservations.push(reservation);
            result[reservation.pickupPlace].people += reservation.people;
            result[reservation.pickupPlace].shownPeople += (reservation.noShow ? 0 : reservation.people);
            result[reservation.pickupPlace].adult += reservation.adult;
            result[reservation.pickupPlace].kid += reservation.kid;
            result[reservation.pickupPlace].infant += reservation.infant;
            result[reservation.pickupPlace].pickupOrder = Team.pickupPlaceOrder[reservation.pickupPlace.toLowerCase()];
            result[reservation.pickupPlace].pickupName = Team.pickupPlaceName[reservation.pickupPlace.toLowerCase()];
            result[reservation.pickupPlace].key = reservation.pickupPlace;
            return result;
        }, {});
        return Object.values(pickupPlaceReservationsMap).sort((a, b) => a.pickupOrder - b.pickupOrder);
    }

    constructor(rawTeam, teamId, basePath, idx = 0) {
        this._ = rawTeam;
        this.id = teamId;
        this.idx = idx;
        this.path = `${basePath}/${teamId}`;
        this.guides = (rawTeam.guides ?? []).map((g) => g.id) ?? ['-'];
        this.message = rawTeam.message;
        this.memo = rawTeam.memo ?? {};
        this.reservations = rawTeam.reservations ? mapObject(rawTeam.reservations,
            ([key, reservation]) => reservation.agencyCode,
            (rawReservation, reservationId) => new Reservation(rawReservation, reservationId, `${this.path}/reservations`)) : {};
        this.pickupPlaces = Team.arrangePickupPlaces(this.reservations);
        [this.people, this.shownPeople, this.adult, this.kid, this.infant] = this.pickupPlaces.reduce((rslt, pickup) => [
            rslt[0] + pickup.people,
            rslt[1] + pickup.shownPeople,
            rslt[2] + pickup.adult,
            rslt[3] + pickup.kid,
            rslt[4] + pickup.infant,
        ], [0, 0,0,0,0]);
        this.snapshotedAt = new Date();
    }

    get ids(){
        const pathes = this.path.split('/').filter((f)=>!!f);
        return {
            date:pathes[1],
            tourId:pathes[3],
            teamId:pathes[5]
        };
    }


    get reservationList() {
        return Object.values(this.reservations);
    }

    get reservationIds() {
        return Object.keys(this.reservations);
    }

    get isValid() {
        return this.reservations.length !== 0;
    }
}
