import mapObject from '../utils/mapObject'

import Team from './Team'

export default class Tour {

  static areaName = {
    'seoul': '서울',
    'busan': '부산',
    'tokyo': '도쿄',
    'osaka': 'osaka',
  }

  static areaPickupName = {
    'seoul': {
      'hongdae': '홍대',
      'myungdong': '명동',
      'dongdaemoon': '동대문',

    },
    'busan': {
      'busan station': '부산역',
      'seomyun': '서면',
      'haeundae': '해운대',
    },
    'tokyo': {
      'tokyo station': '도쿄역',
      'shinjuku-nishiguchi': '신주쿠니시구치역',
    },
    'osaka': {
      'tsurutontan soemoncho ': '츠루톤탄 소에몬초',
    },
  }

  constructor (rawTour, tourId, basePath) {
    this._ = rawTour
    this.basePath = basePath
    this.path = `${basePath}/${rawTour.id}`
    this.id = rawTour.id
    this.area = rawTour.area //Busan
    this.productId = rawTour.productId
    this.product = rawTour.product // 경주
    this.teams = rawTour.teams ? mapObject(rawTour.teams,
      ([key, team]) => team.reservations,
      (rawTeam, teamId, idx) => new Team(rawTeam, teamId, `${this.path}/teams`,
        idx),
    ) : {}
    this.teamInfos = this.teamList.reduce((rslt, team) => {
      rslt.people += team.people
      team.guides.forEach((g) => rslt.guideSet.add(g))
      team.pickupPlaces.forEach(
        (p) => rslt.pickupPlaceSet.add(p.key.toLowerCase()))
      return rslt
    }, {
      people: 0,
      guideSet: new Set(),
      pickupPlaceSet: new Set(),
    })
    this.snapshotedAt = new Date()
  }

  get teamList () {
    return Object.values(this.teams).map((team, idx) => {
      team.idx = (idx + 1)
      return team
    }).sort(({ idx: a }, { idx: b }) => a - b).filter(t => t.people)
  }

  get teamIds () {
    return Object.keys(this.teams)
  }

  get isValid () {
    return this.teamIds.length !== 0
  }
}
