import {useState, useCallback} from 'react';

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';


import {updateVal, removeVal} from '../../controller/firebase';
import {useGuideContext} from "../../controller/context/GuideContext";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from "@mui/material/InputAdornment";

export default function AccountItem({account}) {

    const {guide} = useGuideContext();
    const [edit, setEdit] = useState(false);


    const canChangeCurrency = guide.admin;
    const baseCurrency = guide?.area?.toLowerCase().includes('tokyo') || guide?.area?.toLowerCase().includes('osaka') ? 'JPY' : 'KRW';
    const [currency, setCurrency] = useState(account.currency ?? baseCurrency);


    const handleChangeCurrency = (e, value) => {
        setCurrency(e.target.value);
    };

    const handleToggleEdit = useCallback((e) => {
        setEdit(edit => !edit);
    }, []);

    const handleSubmit = useCallback((e) => {
        e.preventDefault();
        const formData = new FormData(e.target);
        if (formData.get('card') && formData.get('cash')) {
            return alert('Only can use single type of method at once.');
        }
        const data = {
            card: Number(formData.get('card')),
            cash: Number(formData.get('cash')),
            detail: formData.get('memo'),
            memo: formData.get('memo'),
            currency: currency,
        };
        updateVal(account.path, data)
            .then(() => {
                alert('Updated!');
            })
            .catch(() => {
                alert('Fail update!');
            });
    }, [account.path]);

    const handleDelete = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        if (window.confirm('Cannot recover it')) {
            removeVal(account.path)
                .then(() => {
                    alert('Deleted!');
                })
                .catch(() => {
                    alert('Fail delete!');
                });
        }
    }, [account.path]);

    return (
        <>
            <ListItem
                sx={{
                    p: 0,
                }}
            >
                <ListItemButton
                    onClick={handleToggleEdit}
                    sx={{
                        display: 'block',
                        px: 0,
                        py: 0,
                    }}
                >
                    <Box
                        sx={{
                            width: '100%',
                            px: 2,
                            pt: 1,
                            pb: 0,
                            mb: 1,
                        }}
                    >

                        <Box>
                            <Typography
                                gutterBottom
                                sx={{
                                    color: 'gray',
                                    fontSize: '10pt',
                                    fontWeight: '400',
                                }}
                            >
                                {account.time.toLocaleString()}
                            </Typography>
                        </Box>
                        <Box
                            display={'flex'}
                            justifyContent={'space-evenly'}
                        >
                            {
                                account.card
                                    ? (
                                        <Typography
                                            gutterBottom
                                            fontWeight={'600'}
                                            sx={{
                                                flex: 1,
                                                display: 'flex',
                                                alignItems: 'center',
                                                color: 'gray',
                                            }}
                                        >
                                            <Icon sx={{color: 'gray', mr: 1}}>credit_card</Icon>
                                            {account.card?.toLocaleString()} {currency === 'JPY' ? '¥' : '₩'}
                                        </Typography>
                                    ) : null
                            }

                            {
                                account.cash
                                    ? (
                                        <Typography
                                            gutterBottom
                                            fontWeight={'600'}
                                            sx={{
                                                flex: 1,
                                                display: 'flex',
                                                alignItems: 'center',
                                                color: 'gray',
                                                // fontSize: '10pt',
                                            }}
                                        >
                                            <Icon sx={{color: 'gray', mr: 1}}>money</Icon>
                                            {account.cash?.toLocaleString()} {currency === 'JPY' ? '¥' : '₩'}
                                        </Typography>
                                    )
                                    : null
                            }
                        </Box>
                        <Box>
                            <Typography
                                sx={{
                                    color: 'gray',
                                    fontSize: '10pt',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis'
                                }}
                            >
                                {account.memo}
                            </Typography>
                        </Box>

                    </Box>
                </ListItemButton>
            </ListItem>
            <Collapse
                in={edit}
                unmountOnExit={true}
            >
                <Divider/>
                <Box
                    sx={{
                        p: 2,
                        backgroundColor: '#f9f9fd'
                    }}
                    component={'form'}
                    onSubmit={handleSubmit}
                >
                    <Grid container spacing={2}>
                        {
                            canChangeCurrency
                                ? <Grid item xs={2}>
                                    <Select
                                        fullWidth
                                        disabled={!canChangeCurrency}
                                        variant={'outlined'}
                                        onChange={handleChangeCurrency}
                                        value={currency}
                                        sx={{
                                            '&::before': {
                                                display: 'none'
                                            }
                                        }}
                                    >
                                        <MenuItem value={'KRW'}>
                                            ₩
                                        </MenuItem>
                                        <MenuItem value={'JPY'}>
                                            ¥
                                        </MenuItem>
                                    </Select>
                                </Grid>
                                : null
                        }
                        <Grid item xs={canChangeCurrency ? 5 : 6}>
                            <TextField
                                fullWidth
                                id={'card'}
                                name={'card'}
                                label={'CARD'}
                                variant={'outlined'}
                                type={'number'}
                                defaultValue={account.card ?? ''}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={
                                    {
                                        startAdornment: <InputAdornment position="start">{currency === 'JPY' ? '¥' : '₩'}</InputAdornment>
                                    }
                                }
                                sx={{
                                    '& .Mui-disabled::before': {
                                        display: 'none'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={canChangeCurrency ? 5 : 6}>
                            <TextField
                                id={'cash'}
                                name={'cash'}
                                fullWidth
                                label={'CASH'}
                                variant={'outlined'}
                                type={'number'}
                                defaultValue={account.cash ?? ''}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={
                                    {
                                        startAdornment: <InputAdornment position="start">{currency === 'JPY' ? '¥' : '₩'}</InputAdornment>
                                    }
                                }
                                sx={{
                                    '& .Mui-disabled::before': {
                                        display: 'none'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id={'memo'}
                                name={'memo'}
                                fullWidth
                                multiline
                                maxRows={4}
                                label={'MEMO'}
                                variant={'outlined'}
                                defaultValue={account.memo}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                sx={{
                                    '& .Mui-disabled::before': {
                                        display: 'none'
                                    }
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Box
                                display={'flex'}
                                flexDirection={'row-reverse'}
                            >
                                <Button
                                    id={'update'}
                                    type={'submit'}
                                >
                                    Update
                                </Button>
                                <Button
                                    id={'delete'}
                                    type={'button'}
                                    color={'error'}
                                    onClick={handleDelete}
                                >
                                    Delete
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Collapse>
            <Divider/>
        </>
    );
}
